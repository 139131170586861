import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Tracking from '../views/Tracking.vue'


const routes = [
  { path: '', component: Home },
  { path: '/tracking/:id', component: Tracking, props: true }
]
const router = createRouter({
  routes,
  history: createWebHashHistory()
})

export default router
