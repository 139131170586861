<template>
  <div class="page-container">
    <div class="home-photo">
    </div>
    <div class="page-body">
      <img class="mobile-logo" src="./../assets/logo.png" />
      <h1>Welcome to the Little Lantern Shop!</h1>
      <p class="home-text mobile-text">
        Thanks for stopping by! This is a side-project run by a Wellesley alum. On this page you can read more about our lanterns and the production and shipping process. You can check your order status below.
      </p>
      <p class="home-text desktop-text">
        Thanks for stopping by! This is a side-project run by a Wellesley alum. On this page you can read more about our lanterns and the production and shipping process. You can check your order status in the nav bar.
      </p>

      <details>
        <summary>
          About the little lantern
        </summary>
        <div class="content">
          <p>My partner surprised me with a homemade 3D-printed Wellesley lantern for my birthday. I shared a photo in a Wellesley crafting group and a few people asked if we would consider selling them. We decided to go for it if we could find homes for more than 10, and to our surprise, we got over 200 orders in just a few days!</p>

          <p>The lanterns look adorable in potted plants or pencil cups. If you are 5cm tall or shorter, you can also use them as a nice study spot, or lean against them when tying your shoe. 😉</p>
        </div>
      </details>

      <details>
        <summary>
          Lantern materials and dimensions
        </summary>
        <div class="content">
          <p>The lantern measures 16x3 cm, or about 6x1 inches. It is very lightweight at only 5 grams, and is printed from matte black PLA. All lanterns are printed by me at home, and packaged and shipped with Wellesley love.</p>

          <p>Different 3D printers come with different limitations, and our printer is no exception. We print our model with a layer height of .16 millimeter. If you look up close at your lantern, you will be able to see these layers. Around the smallest details of the lantern, you might also be able to see some printing imperfections. I check the quality of each print to make sure there are no large deficiencies, but with the printer we have access to, small imperfections are unavoidable. You can <a href="layers.jpg">see a picture here</a> to see the layers and some of the imperfections you can expect. Typically they look better than this — this is about the worst you can expect.</p>
        </div>
      </details>

      <details>
        <summary>
          Production timelines
        </summary>
        <div class="content">
          <p>Once you’ve ordered a lantern via the Google form, you will receive an email with your confirmation code. The code can be used on this page to check on your place in the queue, the production date, and the status of your lantern. Possible statuses include: Waiting in queue, Contacted for payment, In production, Ready to ship, and Shipped.</p>
          <p>Because each lantern is printed manually, it will take us a little while to get through all the orders. I really appreciate your patience and I'm excited to share these with the Wellesley community and with you!</p>
        </div>
      </details>

      <details>
        <summary>
          How and when will I pay?
        </summary>
        <div class="content">
          <p>When it’s almost your turn in the queue, I’ll send you an email with the final shipping price ($5 per lantern plus shipping costs) and information about how to pay. Once you send the payment, I’ll print and ship your lantern!</p>
        </div>
      </details>

      <details>
        <summary>
          Shipping
        </summary>
        <div class="content">
          <p>I package the lanterns with care, in recycled 100% compostable padded mailers. To save on shipping costs, I use USPS, and orders do not include a tracking number. Packages ship from New Jersey.
          </p>
        </div>
      </details>
      
    </div>
  </div>  
</template>

<script>
export default {
  name: 'Home',
  props: {
    msg: String
  }
}
</script>

<style scoped>


</style>
