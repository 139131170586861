<template>
  <router-link to="/"><img class="desktop-logo" src="./assets/logo.jpg" /></router-link>
  <form @submit="goToStatus" class="desktop-nav flex">
    <label class="nav-link">Confirmation No.:</label>
    <label class="mobile-nav-link">Order:</label>
    <input v-model="trackingId" type="text" />
    <button class="nav-button">Check Status</button>
  </form>
  <router-view/>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      trackingId: ''
    }
  },
  methods: {
    goToStatus() {
      this.$router.push(`/tracking/${this.trackingId}`)
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

body, html {
  margin: 0px;
  padding: 0px;
  background-color: #F8F5F0;
  text-align: left;
}

h1, h2, h3 {
  font-family: futura, sans-serif;
  letter-spacing: 1px;
  color: black;
  text-align: left;
}

h1 {
  font-weight: 400;
  font-size: 18px;
}

.flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.desktop-nav {
  margin: 0px auto;
}

.nav-link {
  display: none;
  font-family: futura-pt, sans-serif;
  text-transform: uppercase;
  letter-spacing: 3px;
  text-decoration: none;
  color: black;
  padding: 0px 10px;
}

.nav-button {
  margin-left: 10px;
  border-radius: 100px;
  border: none;
  background-color: #93AF70;
  padding: 10px 20px;
  cursor: pointer;
  font-family: 'futura-pt';
  font-size: 14px;
}

.nav-button:hover,
.nav-button:focus {
  background-color: #6c844c;
}

.desktop-logo {
  display: none;
}

input {
	margin: 15px 0;
  font-family: 'futura-pt';
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 10px 15px;
  outline: none;
	border: 1px solid #bbb;
	border-radius: 100px;
	display: inline-block;
}

.page-body {
  padding: 20px;
  overflow: auto;
}

.mobile-logo {
  display: block;
  width: 250px;
  margin: 30px auto;
}

.mobile-text {
  display: block;
}

.mobile-nav-link {
  display: block;
  margin: 0px 10px 0px 20px;
}

.desktop-text {
  display: none;
}

.page-container {
  text-align: left;
}

.home-photo {
  grid-area: photo;
  background-image: url("./assets/lantern1.jpg");
  background-size: cover;
}

.tracking-photo {
  grid-area: photo;
  background-image: url("./assets/lantern0.jpg");
  background-size: cover;
}

summary {
  padding: 1em;
  background: #cedfb9;
  margin-bottom: 1em;
  cursor: pointer;
  outline: none;
  border-radius: 0.3em;
  font-weight: bold;
}

details[open] summary ~ * {
  animation: open 0.5s ease-in-out;
}

.content {
  margin: 0px 2em 2em;
}

/* Desktops and laptops ----------- */
@media only screen and (min-width : 800px){

  h1 {
    font-size: 25px;
  }

  .page-container {
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-template-areas: 
      "photo body";
    height: calc(100vh - 60px);
    text-align: left;
    overflow: auto;
  } 

  .page-body {
    grid-area: body;
    padding: 60px 80px;
  }

  .desktop-logo {
    display: block;
    width: 160px;
    padding: 10px 15px;
    background-color: white;
    border-radius: 0px 0px 50% 50%;
    position: absolute;
    left: 20px;
  }

  .mobile-logo {
    display: none;
  }

  .desktop-nav {
    display: flex;
    height: 60px;
    background-color: #FAECD2;
    padding: 0px 20px 0px 250px;
    justify-content: flex-end;
  }

  .nav-link {
    display: block;
  }

  .mobile-text, .mobile-nav-link {
    display: none;
  }

  .desktop-text {
    display: block;
    width: 525px;
  }

  .mobile-form {
    display: none;
  }
}


</style>
