<template>
  <div class="page-container">
    <div class="tracking-photo">
    </div>
    <div class="page-body">
      <img class="mobile-logo" src="./../assets/logo.png" />
      <template v-if="!loading && !error">
        <h1>Your Order Status</h1>
        <table>
          <tr>
            <td>Name</td>
            <td>{{trackingData[4]}}</td>
          </tr>
          <tr>
            <td>Lanterns ordered</td>
            <td>{{trackingData[5]}}</td>
          </tr>
          <tr>
            <td>Shipping address</td>
            <td>{{trackingData[6]}}</td>
          </tr>
          <tr>
            <td>Place in queue</td>
            <td>{{trackingData[1]}}</td>
          </tr>
          <tr>
            <td>Est. date of production</td>
            <td>{{trackingData[2]}}</td>
          </tr>
        </table>
        
        <h3 class="header-status-log">Status Log</h3>
        <ul>
          <li v-for="update in updates" :key="update">{{update}}</li>
        </ul>
      </template>
      <template v-else>
        <h1>Whoops! That's not a thing.</h1>
        <p>The tracking code you entered isn't in our database. Please try again. If this is the code you were given, let us know and we'll fix the issue.</p>
      </template>
    </div>
  </div>  
</template>

<script>
export default {
  name: 'Tracking',
  props: {
    id: String,
  },
  data() {
    return {
      loading: true,
      trackingData: null,
      updates: [],
      error: false,
    };
  },
  mounted() {
    this.loadTrackingData();
  },
  watch: {
    id() {
      this.loadTrackingData();
    }
  },
  methods: {
    loadTrackingData() {
      this.trackingData = null;
      this.loading = true;
      this.error = false;

      let req = new XMLHttpRequest();
      req.onload = () => {
        this.trackingData = JSON.parse(req.response);
        this.updates = this.trackingData.slice(8);
        this.loading = false;
      };
      req.onerror = () => {
        this.loading = false;
        this.error = true;
      };
      req.open("GET", `//api.littlelanterns.pleasepress.space/orders/${this.id}`);
      req.send();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.header-status-log {
  font-weight: normal;
  margin-bottom: 0px;
}

table {
  border-collapse: collapse;
  border: 2px solid rgb(200,200,200);
}

td, th {
  border: 1px solid rgb(190,190,190);
  padding: 10px 20px;
}

th {
  background-color: rgb(235,235,235);
}

tr:nth-child(even) td {
  background-color: rgb(250,250,250);
}

tr:nth-child(odd) td {
  background-color: rgb(245,245,245);
}

tr td:first-child {
  font-weight: bold;
}

caption {
  padding: 5px;
}

</style>
